<template>
    <section class="text-base">
        <PageHeader wide>
            <h1>{{ title }}</h1>
        </PageHeader>
        <div class="container-left flex flex-col gap-8 mt-8">
            <div>
                <input
                    v-model="filter"
                    type="text"
                    class="w-full md:w-96"
                    :placeholder="$t('ProductSeries.FilterBySeries')" />
            </div>
            <div>
                <table id="series-table" class="table table-striped max-w-screen-xl">
                    <thead>
                        <tr class="text-left">
                            <th class="sm:w-1/5 cursor-pointer" @click="toggleSortField('BasePartNumber')">
                                {{ $t("Global.Series") }}
                            </th>
                            <th>{{ $t("PartCategory.Category") }}</th>
                            <th class="sm:w-1/5 cursor-pointer" @click="toggleSortField('TotalProducts')">
                                {{ $t("ProductSeries.NumberOfProducts") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in pageRows" :key="row.Id">
                            <td>
                                <NuxtLink class="whitespace-nowrap" :to="row.link">
                                    {{ row.BasePartNumber }}
                                </NuxtLink>
                            </td>
                            <td>
                                <div class="flex flex-wrap gap-2 items-center">
                                    <template v-for="(category, index) in row.categories" :key="category.link">
                                        <i v-if="index > 0" class="fass fa-chevron-right"></i>
                                        <NuxtLink :to="category.link">{{ category.label }}</NuxtLink>
                                    </template>
                                </div>
                            </td>
                            <td>{{ formatNumber(row.TotalProducts) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationControls
                v-if="hasRows"
                id="series-pagination"
                v-model:page="page"
                v-model:size="pageSize"
                :num-items="filteredRows.length"
                :page-size-options="pageSizes">
            </PaginationControls>
            <div v-if="!hasRows" v-html="$t('Search.MessageNoMatchesHeader')"></div>
        </div>
    </section>
</template>
<script setup lang="ts">
const route = useRoute();
const { t } = useI18n();
const localePath = useLocalePath();
const manufacturer = useStateManufacturer();
const { company } = route.params;
const filter = ref("");
const sort = ref<Sort>({ field: "BasePartNumber", descending: false });
const page = ref(1);
const pageSizes = [10, 20];
const pageSize = ref(pageSizes[0]);

type SortField = "BasePartNumber" | "TotalProducts";
type Sort = { field: SortField; descending: boolean };

type AllProductSeriesResponse = {
    Series: ProductSeries[];
    Manufacturer: ManufacturerDetails;
    Categories: Category[];
};

const { data } = await useFetchLocaleApi<AllProductSeriesResponse>("api/product-series/all", {
    query: { virtualPath: company },
    onResponse({ response }) {
        manufacturer.value = response._data.Manufacturer;
    },
});

const title = computed(() => t("ProductSeries.ProductSeriesFor", [manufacturer.value?.Name]) + " - TrustedParts.com");
const description = computed(() => t("ProductSeries.SeriesListMetaDescriptionFormat", [manufacturer.value?.Name]));

useSeoMeta({ title, ogTitle: title, description, ogDescription: description });

const sortedRows = computed(() => {
    if (!Array.isArray(data.value?.Series)) return [];
    const series = [...data.value.Series];
    if (sort.value.field == "BasePartNumber") {
        series.sort((a, b) => a.BasePartNumber.localeCompare(b.BasePartNumber));
    } else if (sort.value.field == "TotalProducts") {
        series.sort((a, b) => a.TotalProducts - b.TotalProducts);
    }
    if (sort.value.descending) series.reverse();
    return series;
});

const filteredRows = computed(() => {
    if (!filter.value) return sortedRows.value;
    const lowerFilter = filter.value.toLowerCase();
    return sortedRows.value.filter((item) => item.BasePartNumber.toLocaleLowerCase().includes(lowerFilter));
});

const pageRows = computed(() => {
    const start = (page.value - 1) * pageSize.value;
    const end = start + pageSize.value;
    return filteredRows.value
        .slice(start, end)
        .map((row) => ({ link: seriesLink(row), categories: seriesCategories(row), ...row }));
});

const hasRows = computed(() => filteredRows.value.length > 0);

watch(filter, () => (page.value = 1));
watch(pageSize, () => (page.value = 1));

function seriesLink(series: ProductSeries) {
    return localePath(
        ["/manufacturers", manufacturer.value?.VirtualPath, encodeURIComponent(series.BasePartNumber)].join("/")
    );
}

function seriesCategories(series: ProductSeries) {
    const category = data.value?.Categories.find((item) => item.Id == series.MasterCategoryId);
    if (!category) return [];
    const { Parents: parents, ...other } = category;
    return parents?.concat(other).map((cat) => ({ label: cat.Name, link: localePath("/part-category/" + cat.Path) }));
}

function toggleSortField(field: SortField) {
    if (field == sort.value.field) {
        sort.value.descending = !sort.value.descending;
    } else {
        sort.value = { field, descending: field == "TotalProducts" };
    }
    page.value = 1;
}
</script>
